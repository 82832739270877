import React, { useState } from 'react';
import { ToggleButton } from './toggle/ToggleButton';

import './App.css';

function App() {
  const [selected, setSelected] = useState(false);
  return (
    <div className='App'>
      <header className='App-header'>
        <ToggleButton
          selected={selected}
          toggleSelected={() => {
            setSelected(!selected);
          }}
        />
      </header>
      <footer className='App-footer'>
        Copyright © 2020 Switch.rs - All Rights Reserved
      </footer>
    </div>
  );
}

export default App;
